<template>
    <div>
        <reset-pass></reset-pass>
    </div>
</template>
<script>

import ResetPass from '@/components/ResetPass.vue';
export default {
    components:{
        'reset-pass': ResetPass
    }
}
</script>