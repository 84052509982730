<template>
  <error-page></error-page>
</template>

<script>
import ErrorPage from "@/components/ErrorPage.vue";
export default {
  components: {
    "error-page": ErrorPage,
  },
};
</script>

<style></style>
